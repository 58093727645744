import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import background from "../../Figures/Site_Header_Background.png"
import bell_mechanism from "../../Figures/BellMechanism.jpg"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO001Overview.js"

export default function BellRinging() {
  return (
      <div>
      <div>
    <Layout>
    </Layout>
    <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO001 - Rotary cellphone</h1>
      <br />
      <h2 style={{color: "white"}}>Ringing the bell</h2>
        </div>
      </div>
      </div>
      <div>
      <div className={pageLayout.text}>
        <h2>Intro</h2>
        <p>The ringer of the phone is actuated by a hammer and an electromagnet. Alternating current through a solenoid, continuously polarises the magnet two different ways. This makes the hammer swing both ways, hitting the bells.</p>
        <p>During the days of the actual phone this was done with around 100 Volts at a frequency of 20 Hz, but I am hoping that 12 V and 50 Hz will do the trick.</p>
        <h2>AC/DC</h2>
        <p>I want the phone to be working continously and not have to hassle with batteries. Since I anyway need the AC circuit for the bell, I plan to use the 
          power from an outlet, convert it to 12 V DC for the electronics and tap from the incomming AC supply for the bell. The ACDC converter I ended up buying is very similar to <a href="https://www.tme.eu/be/nl/details/irm-20-12/inbouwvoedingen/mean-well/">this one</a>.
          <br />
          A simple 5V relay shall be used to actuate the solenoid intermittently with 1s intervals. The figure below shows the electromagnetical actuator in this phone:
          <br />
          </p>
          <p>
          <figure>
        <img width="40%"  src={bell_mechanism} style={{borderRadius: 20, marginLeft: "30%" , marginRight: "30%"}} alt="Mechanism bell" />
        <figcaption style={{textAlign: "center"}}>Fig.1 - Mechanism of solenoid and hammer for ringing the bell of the phone.</figcaption>
        </figure>
        </p>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}